import React from 'react';
import { graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import Layout from '../components/Layout';
import ProductLinkContainer from '../components/ProductLinkContainer';
import { Product } from '../types';
import { HOME_ROUTE, WEDDING_ROUTE } from '../constants';
import WaveSVG from '../components/WaveSVG';
import { convertCentsToCurrency } from '../utils';

interface ProductListPageComponentProps {
    products: Product[];
    pageTitle: string;
    pageHeader: string;
    pageMessage: string;
    pageColor: string;
}

const ProductListPageComponent: React.FC<ProductListPageComponentProps> = ({ products, pageTitle, pageHeader, pageMessage, pageColor }) => {
    return (
        <div>
            <section className={`page-header-section`} style={{ backgroundColor: pageColor }}>
                <div className="layout-container">
                    <div className="header-text-container">
                        <h3>{pageHeader}</h3>
                        <p className="padding-bottom-1">{pageMessage}</p>
                    </div>
                </div>
                <div className="svg-container" style={{ color: pageColor }}>
                    <WaveSVG />
                </div>
            </section>
            <section className='padding-bottom-4'>
                <div className="layout-container">
                    <h6>{pageTitle}</h6>
                    <div className="grid-container product-grid">
                        {products.map((product, index) => {
                            return <ProductLinkContainer product={product} key={`product-${index}`} />
                        })}
                    </div>
                </div>
            </section>

        </div>
    )
}

const ProductListPage = ({ data: { allProduct: { nodes }, pageContent }, pageContext: { category } }) => {
    const pageTitle = `Shop ${pageContent.seasonalPageTitle}`;
    return (
        <Layout pageTitle={pageTitle} pathname={category === 'Wedding' ? WEDDING_ROUTE : HOME_ROUTE}>
            <ProductListPageComponent 
                products={nodes as Product[]}
                pageTitle={pageTitle}
                pageHeader={pageContent.seasonalPageHeader}
                pageMessage={pageContent.seasonalPageMessage}
                pageColor={pageContent.seasonalPageColor}
            />
        </Layout>
    )
}

export default ProductListPage;

export const query = graphql`
    query {
        allProduct(sort: {fields: sortRank, order: ASC}, filter: {category: {eq: "Seasonal"}}) {
            nodes {
                productImage {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                name
                featured
                id
                minPrice
                path
                subcategory
            }
        }
        pageContent {
            seasonalPageHeader
            seasonalPageMessage
            seasonalPageTitle
            seasonalPageColor
        }
    }
`